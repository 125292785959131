.ui.priority.label[data-v-3bc6e43e] {
  font-weight: normal;
  letter-spacing: 0.2px;
}
.ui.priority.label.selected[data-v-3bc6e43e] {
    font-weight: bold;
}
.ui.priority.label.red[data-v-3bc6e43e] {
    background-color: #f88177 !important;
    color: #fff !important;
}
.ui.priority.label.yellow[data-v-3bc6e43e] {
    background-color: #fdee6e !important;
    color: #444 !important;
}
.ui.priority.label.green[data-v-3bc6e43e] {
    background-color: #caebc5 !important;
    color: #444 !important;
}
