.global-settings[data-v-ee5be93f] .piracy-time-limit .field,
.global-settings[data-v-ee5be93f] .piracy-traffic-light .field {
  margin-right: 0 !important;
}
.global-settings[data-v-ee5be93f] .piracy-time-limit .ui.input,
.global-settings[data-v-ee5be93f] .piracy-traffic-light .ui.input {
  min-width: inherit !important;
  max-width: inherit !important;
  width: 100px !important;
}
.global-settings[data-v-ee5be93f] .piracy-time-limit .ui.input input,
  .global-settings[data-v-ee5be93f] .piracy-traffic-light .ui.input input {
    text-align: right;
    -webkit-appearance: textfield;
            appearance: textfield;
}
