.sequence.campaigns.list .item[data-v-1c044b24] {
  display: inline-block;
}
.sequence.campaigns.list .item[data-v-1c044b24]:after {
  display: inline-block;
  visibility: visible;
  margin-right: 0.5em;
  content: ", ";
}
.sequence.campaigns.list .item[data-v-1c044b24]:last-child:after {
  content: "";
}
