.taskList[data-v-9ef4a2e0] .actions {
  margin-bottom: 1.5em;
}
.taskList[data-v-9ef4a2e0] .actions .item-adder,
  .taskList[data-v-9ef4a2e0] .actions .search.input {
    margin-right: 1em;
}
.taskList[data-v-9ef4a2e0] .message {
  word-break: break-all;
}
