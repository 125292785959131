.responsesView[data-v-27065fa5] .filterButtons button {
  margin-bottom: 0;
}
.responsesView[data-v-27065fa5] .filterButtonParent {
  position: relative;
}
.responsesView[data-v-27065fa5] .filterButton {
  margin: 0;
  position: absolute;
  top: 24px;
  right: 0;
}
.responsesView[data-v-27065fa5] .filterButton .basic.button {
  margin-top: 0;
}
.responsesView[data-v-27065fa5] .responses-tabs {
  margin-top: 1em;
}
.responsesView[data-v-27065fa5] .responses-tabs .container {
  margin-bottom: 1em;
  width: 100%;
}
.responsesView[data-v-27065fa5] .responses-tabs .container .ui.list > .item {
  margin-bottom: 0.5em;
}
.responsesView.tabView[data-v-27065fa5] .ui.grid > .row > .column:first-child {
  width: 100%;
}
.responsesView[data-v-27065fa5] .no-results {
  margin-top: 0;
}
.download-attachments-container[data-v-27065fa5] {
  display: flex;
  justify-content: flex-end;
}
.edited-label[data-v-27065fa5] {
  float: right;
}
