.accordion.ui > .address-form:last-child {
  margin-bottom: 1rem; }

.addressItem .addressHeader {
  display: flex; }
  .addressItem .addressHeader:hover {
    cursor: pointer; }
  .addressItem .addressHeader .warning {
    color: red; }
  .addressItem .addressHeader .red.cancel.link.icon {
    margin-left: auto; }
