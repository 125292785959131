.filterOption {
  margin-top: 2em; }

.grid.call-dashboard {
  width: calc(100vw - 20px); }

.custom-tooltip {
  background-color: rgba(255, 255, 255, 0.9);
  padding: 5px;
  border-radius: 4px; }

.ui.bottom.attached.segment.tab > .ui.basic.segment {
  height: 628px; }

.call-dashboard .agent-table {
  padding: 0.5em 0;
  text-align: center; }

.call-dashboard .agent-table,
.call-dashboard .ReactTable,
.call-dashboard .table-inner > div,
.call-dashboard .ReactTable .rt-table {
  height: 100%; }

.call-dashboard .agent-table .rt-tr-group:hover {
  background-color: #ffff99; }

.whiteBackgroundWrapper {
  background-color: white;
  padding: 2em;
  height: 628px; }
