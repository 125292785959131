.notes .card i.icon {
  margin-top: 0.2em; }

.notes .card .icon.column i.icon:before {
  font-size: 1.7em; }

.notes .card .buttons {
  margin-bottom: 0; }

.notes .card .buttons > button {
  color: #68b9e4;
  font-weight: bold;
  font-size: 0.9em; }

.notes .card .buttons > button > i.icon {
  margin-left: 0.1em !important; }

.notes .card .buttons > button > i.icon:before {
  font-size: 1.05em; }

.notes .card .buttons > .ui.textLink.button {
  padding: 0 0 0.1em 0 !important;
  margin: 0 !important; }

.notes .card .edit.button {
  padding: 0.55em 0.4em 0.55em 0.5em !important; }

.notes .form.item {
  background: white;
  margin-bottom: 1.5em;
  border-radius: 0.3rem; }

.notes dd {
  -webkit-margin-start: 30px;
          margin-inline-start: 30px; }
