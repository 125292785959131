.phone-numbers-list[data-v-dff5374c] .enabled-label {
  font-weight: bold;
  font-size: 0.9em;
}
.phone-numbers-list[data-v-dff5374c] .enabled-label .icon {
    margin-left: 3px;
    vertical-align: -1px;
}
.phone-numbers-list[data-v-dff5374c] .enabled-label.none .icon {
    vertical-align: -3px;
}
.phone-numbers-list[data-v-dff5374c] .enabled-label .call.icon {
    vertical-align: -2px;
}
.phone-numbers-list[data-v-dff5374c] tr.editing td {
  padding-top: 2px;
  padding-bottom: 1px;
}
.phone-numbers-list[data-v-dff5374c] .email-row {
  display: flex;
  overflow: scroll;
  overflow-y: hidden;
}
.phone-numbers-list[data-v-dff5374c] .email-row::-webkit-scrollbar {
    display: none;
}
.phone-numbers-list[data-v-dff5374c] td:first-child {
  width: 240px;
}
.phone-numbers-list[data-v-dff5374c] td:nth-child(2) {
  width: 90px;
}
.phone-numbers-list[data-v-dff5374c] td:nth-child(3) {
  width: 150px;
}
.phone-numbers-list[data-v-dff5374c] .ui.input.ui.input input,
.phone-numbers-list[data-v-dff5374c] .ui.selection.ui.selection {
  border: none;
  border-top: none;
  border-left: none;
  border-right: none;
  border-radius: 0;
}
.phone-numbers-list[data-v-dff5374c] .ui.input.ui.input input[name="edit-ext"],
  .phone-numbers-list[data-v-dff5374c] .ui.selection.ui.selection[name="edit-ext"] {
    padding-left: 0;
    padding-right: 0;
}
.phone-numbers-list[data-v-dff5374c] .actions > div {
  text-align: right;
  display: flex;
}
.phone-numbers-list[data-v-dff5374c] .actions > div .icon {
    margin: 0 0.5em 0.3em 0;
}
.phone-numbers-list[data-v-dff5374c] .actions > div .icon.cancel {
    color: #fe0202;
}
.phone-numbers-list[data-v-dff5374c] .actions > div .icon.check {
    color: #21a712;
}
