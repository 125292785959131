.rdw-editor {
  min-height: 100px;
  max-height: 250px;
  border: 1px solid rgba(34, 36, 38, 0.15);
  padding: 5px !important;
  border-radius: 2px !important;
  font-family: "Open Sans", Arial, Helvetica, sans-serif; }

.rdw-editor.read-only {
  background: #ffffff; }

.hide-toolbar {
  display: none !important; }
