.incoming-call[data-v-939dd2ab] {
  padding: 0;
  width: 400px;
  max-width: 400px;
  border-radius: 10px;
  box-shadow: 0px 0px 5px #ebebeb;
  border: 1px solid #ccc;
  border-bottom: 0;
  position: fixed;
  z-index: 99;
  margin-left: 70%;
  bottom: 0;
}
.incoming-call .header[data-v-939dd2ab] {
    border-radius: 10px 10px 0 0;
    background-color: #ebebeb;
    color: #444444;
    font-weight: bold;
    transition: background-color 0.3s;
    cursor: pointer;
}
@-webkit-keyframes blink-data-v-939dd2ab {
0% {
    background-color: #ffff93;
}
50% {
    background-color: #ebebeb;
}
100% {
    background-color: #ffff93;
}
}
@keyframes blink-data-v-939dd2ab {
0% {
    background-color: #ffff93;
}
50% {
    background-color: #ebebeb;
}
100% {
    background-color: #ffff93;
}
}
.incoming-call .header .title[data-v-939dd2ab] {
      padding: 15px 0 15px 40px;
}
.incoming-call .header .actions i[data-v-939dd2ab] {
      color: #b4b4b4;
      padding: 15px 0 !important;
}
.incoming-call .header.calling[data-v-939dd2ab] {
      -webkit-animation: blink-data-v-939dd2ab 1s infinite;
              animation: blink-data-v-939dd2ab 1s infinite;
}
.incoming-call .display[data-v-939dd2ab] {
    padding: 15px 0;
    background-color: #01004c;
    color: #fff;
}
.incoming-call .display p[data-v-939dd2ab] {
      text-align: center !important;
}
.incoming-call .display .call-title[data-v-939dd2ab] {
      font-size: 16px;
}
.incoming-call .display .caller[data-v-939dd2ab] {
      margin: 60px 0;
}
.incoming-call .display .caller .name[data-v-939dd2ab] {
        font-size: 20px;
        font-weight: bold;
        margin: 0;
}
.incoming-call .display .caller .phone[data-v-939dd2ab] {
        font-size: 15px;
}
.incoming-call .display .actions[data-v-939dd2ab] {
      color: #fff;
      text-align: center;
}
.incoming-call .display .actions button[data-v-939dd2ab] {
        transition: all 0.3s;
}
.incoming-call .display .actions button[data-v-939dd2ab]:hover {
          -webkit-filter: brightness(1.2);
                  filter: brightness(1.2);
}
.incoming-call .display .actions .accept[data-v-939dd2ab] {
        background-color: #3bb505;
        margin-right: 40px;
}
.incoming-call .display .actions .decline[data-v-939dd2ab] {
        background-color: #f44432;
}
.incoming-call .display .actions .decline i[data-v-939dd2ab] {
          -webkit-transform: rotate(225deg);
                  transform: rotate(225deg);
}
.incoming-call .form[data-v-939dd2ab] {
    background-color: #fff;
    padding: 15px;
}
