.permission-category-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%; }

.permission-category-buttons .ui.checkbox {
  flex: 1 1;
  margin-right: 10px; }

.permission-category-buttons .close.icon {
  flex-shrink: 0; }

.permission-container,
.title-container {
  display: flex; }

.permission-category-title {
  min-width: 20%;
  font-weight: bold; }

.ui.accordion .title-container {
  border: 1px solid #e7e7e8;
  padding: 10px !important;
  margin-bottom: 10px !important; }

.content.active {
  border: 1px solid #e7e7e8;
  padding: 10px 5px !important; }

.permission-categories-accordion {
  margin-top: 10px; }
