.RichEditor-root {
  background: #fff;
  border: 1px solid #ddd;
  font-family: "Open Sans", Arial, Helvetica, sans-serif;
  font-size: 14px;
  padding: 15px; }

.RichEditor-editor {
  border-top: 1px solid #ddd;
  cursor: text;
  font-size: 16px;
  margin-top: 10px; }

.RichEditor-editor.readonly {
  border-top: none;
  margin-top: -15px; }

.RichEditor-editor .public-DraftEditorPlaceholder-root,
.RichEditor-editor .public-DraftEditor-content {
  margin: 0 -15px -15px;
  padding: 15px; }

.RichEditor-editor .public-DraftEditor-content {
  min-height: 100px; }

.RichEditor-hidePlaceholder .public-DraftEditorPlaceholder-root {
  display: none; }

.RichEditor-editor .RichEditor-blockquote {
  border-left: 5px solid #eee;
  color: #666;
  font-family: "Hoefler Text", "Georgia", serif;
  font-style: italic;
  margin: 16px 0;
  padding: 10px 20px; }

.RichEditor-editor .RichEditor-textAlignment > div {
  display: inline; }

.RichEditor-editor .RichEditor-textAlignment.left {
  text-align: left; }

.RichEditor-editor .RichEditor-textAlignment.center {
  text-align: center; }

.RichEditor-editor .RichEditor-textAlignment.right {
  text-align: right; }

.RichEditor-editor .RichEditor-textAlignment.justify {
  text-align: justify; }

.RichEditor-editor .public-DraftStyleDefault-pre {
  background-color: rgba(0, 0, 0, 0.05);
  font-family: "Inconsolata", "Menlo", "Consolas", monospace;
  font-size: 16px;
  padding: 20px; }

.RichEditor-controls {
  font-family: "Helvetica", sans-serif;
  font-size: 14px;
  margin-bottom: 5px;
  -webkit-user-select: none;
          user-select: none; }

.RichEditor-styleButton {
  color: #999;
  cursor: pointer;
  margin-right: 16px;
  padding: 2px 0;
  display: inline-block;
  background: none;
  outline: none;
  border: none; }

.RichEditor-styleButton.UndoRedoButton {
  font-size: 1.2em;
  font-weight: bold; }

.RichEditor-styleButton > .list.icon {
  padding: 0 !important; }

.RichEditor-activeButton {
  color: #5890ff; }

.editor {
  box-sizing: border-box;
  border: 1px solid #ddd;
  cursor: text;
  padding: 16px;
  border-radius: 2px;
  margin-bottom: 2em;
  box-shadow: inset 0px 1px 8px -3px #ababab;
  background: #fefefe; }

.editor :global(.public-DraftEditor-content) {
  min-height: 140px; }

.RichEditor-editor div[id^="mentions-list"] {
  z-index: 30; }

.DraftEditor-editorContainer span {
  white-space: break-spaces; }
