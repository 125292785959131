.profilePic {
  width: 120px !important;
  height: 120px !important; }

.default.profilePic {
  width: 120px !important;
  height: 120px !important;
  color: #fff;
  background-color: #ddd;
  outline: 2px dashed #777;
  margin: 2px;
  box-shadow: none !important;
  line-height: 0.8em !important;
  font-size: 5em !important; }

.passwordItem--right {
  color: green; }

.passwordItem--wrong {
  color: red; }
