.full-height {
  height: 100vh; }

.container {
  padding-top: 10px;
  padding-left: 30px;
  padding-right: 30px; }

.float-right {
  float: right; }

.ui.salmon.button {
  background-color: #ff6666;
  color: #fff; }

.color-inherit {
  color: inherit; }
  .color-inherit:hover {
    color: inherit; }

.searchInput {
  margin-right: 5px; }

.bg {
  background-color: #f0f0f0; }

.table-middle-icon {
  margin-left: 1em !important;
  margin-right: 1em !important; }

.campaign-segment {
  overflow: auto;
  padding: 1.5em; }

td > a {
  color: #333; }

.list .item .icon.tag-button {
  top: -0.2rem;
  left: -0.6rem; }

.list .item .icon.tag-button + div.content {
  position: relative;
  left: -0.6rem;
  height: 35px; }

.list .item .icon.tag-button .tag.icon {
  position: initial; }
