.inbox-filters[data-v-f247f561] {
  margin-bottom: 1em;
}
.inbox-filters .filter.icon[data-v-f247f561] {
    margin-left: 0.6em;
    margin-right: 0.8em;
}
.inbox-filters[data-v-f247f561] .inline.field .selection.dropdown {
    font-size: 0.83em;
    min-width: 250px !important;
}
