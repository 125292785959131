.audioplayer[data-v-f406d643] {
  display: flex;
  height: 50px;
  border-radius: 50px;
  padding: 15px;
  background-color: rgba(0, 0, 0, 0.05);
}
.audioplayer .play[data-v-f406d643],
  .audioplayer .pause[data-v-f406d643],
  .audioplayer .download[data-v-f406d643],
  .audioplayer .forward[data-v-f406d643],
  .audioplayer .volume[data-v-f406d643] {
    display: flex;
    margin: 0px 6px;
}
.audioplayer .play[data-v-f406d643]:hover,
    .audioplayer .pause[data-v-f406d643]:hover,
    .audioplayer .download[data-v-f406d643]:hover,
    .audioplayer .forward[data-v-f406d643]:hover,
    .audioplayer .volume[data-v-f406d643]:hover {
      cursor: pointer;
}
.audioplayer .timeline[data-v-f406d643] {
    display: flex;
    width: 200px;
    height: 5px;
    margin: 7px 10px;
    border-radius: 15px;
    background: rgba(0, 0, 0, 0.3);
}
.audioplayer .timeline[data-v-f406d643]:hover {
      cursor: pointer;
}
.audioplayer .playhead[data-v-f406d643] {
    display: flex;
    width: 4px;
    height: 5px;
    border-radius: 50%;
    background: black;
}
