.tabView .filterButton {
  margin-right: 2em;
  margin-bottom: 0.8em;
  font-size: 0.85em;
  color: #333;
  text-align: right; }

.top-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem; }
  .top-controls .left-control {
    flex: 1 1; }
  .top-controls .center-control {
    flex: 1 1;
    display: flex;
    justify-content: center; }
  .top-controls .right-control {
    flex: 1 1;
    display: flex;
    justify-content: flex-end; }

.segment.tab .tabView {
  padding: 1.5em !important; }

.tabView .filterButton .basic.button {
  padding: 0.6em !important;
  background-color: rgba(255, 255, 255, 0.7) !important;
  margin-top: 0.7em;
  margin-left: 0.5em;
  box-shadow: 0px 0px 3px #ccc; }

.tabView .filterButton .basic.button:hover,
.tabView .filterButton .basic.button:active,
.tabView .filterButton .basic.button:focus {
  background-color: white;
  box-shadow: 0px 0px 3px 1px #ccc; }

.tabView .filterButtons button:first-of-type {
  margin-left: 0.7em;
  background-color: #e7e7e7 !important; }

.tabView .filterButtons button {
  margin-left: 0.2em;
  margin-bottom: 1em; }

.tabView .pagination {
  -webkit-transform: scale(0.8);
          transform: scale(0.8); }

.tabView > .item > .header {
  font-size: 1.1em;
  color: #333 !important;
  margin-bottom: 1.2em !important; }

.tabView > .item {
  width: 800px; }

.fluid.tabView > .item {
  width: 100%; }

@media screen and (max-width: 1170px) {
  .tabView > .item {
    width: initial;
    margin-right: 2em; } }

.tabView .ui.cards {
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 0.5em; }

.tabView .ui.cards > .card {
  margin: 0.65em 0.5em;
  box-shadow: 0px 0px 5px 1px #ddd;
  overflow: hidden; }

.tabView .card .icon.column i.icon:before {
  font-size: 1.3em; }

.tabView .ui.grid > .row > .column:first-child {
  width: initial;
  margin-right: 1em; }

.tabView .card .main.column {
  flex: 1 1 auto;
  color: #666; }

.tabView .card .main.column > .header {
  font-size: 1.1em;
  color: #333;
  margin-bottom: 0.2em; }

.tabView .card .main.column > span {
  font-size: 0.9em;
  margin-right: 0.8em;
  display: inline-block; }

.tabView .card .main.column > .message {
  margin-top: 0.8em;
  overflow-wrap: break-word; }

.tabView .card .buttons {
  justify-content: flex-end !important;
  margin-top: 1.2em;
  margin-bottom: 0.5em; }

.tabView .no-results {
  margin-top: 2em;
  text-align: center; }
