.dialer-header[data-v-61b3d7a7] {
  display: flex;
  flex-direction: column;
  padding: 2em 1em 1.5em 2em;
  background-color: #d9d9d9;
  border-bottom: 1px solid #ccc;
}
.dialer-header .dialer-info[data-v-61b3d7a7] {
    display: flex;
}
.dialer-header .dialer-info p[data-v-61b3d7a7] {
      font-size: 1.1rem;
      font-weight: bold;
      margin-left: 1em;
}
.dialer-header .dialer-info p[data-v-61b3d7a7]:last-of-type {
      margin-right: 5em;
}
.dialer-header .control-details[data-v-61b3d7a7] {
    display: flex;
}
.dialer-header .control-details .dialer-controls[data-v-61b3d7a7] {
      display: flex;
      flex-direction: column;
      margin-right: 5.5em;
}
.dialer-header .control-details .dialer-controls i.blue.icon[data-v-61b3d7a7] {
        color: #000066 !important;
}
.dialer-header .control-details .dialer-controls .ui.icon.button > .icon[data-v-61b3d7a7] {
        margin-right: 0.3em !important;
}
.dialer-header .control-details .dialer-details[data-v-61b3d7a7] {
      display: flex;
      flex: 1 1;
}
.dialer-header .control-details .dialer-details .dialer-detail[data-v-61b3d7a7] {
        margin-right: 1.5em;
}
.dialer-header .control-details .dialer-details .dialer-detail p[data-v-61b3d7a7] {
          font-size: 1.3rem;
          font-weight: bold;
}
.dialer-header .control-details .dialer-details .dialer-detail.disabled[data-v-61b3d7a7] {
          opacity: 0.45;
}
