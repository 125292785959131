.pipeline[data-v-1b84dabc] .tabular.menu {
  background: #f6f6f6 !important;
  margin-top: 0.5em !important;
  padding: 2em 1em 1em 1em;
  min-height: 7em !important;
}
.pipeline[data-v-1b84dabc] .tabular.menu.ui.tabular {
    border-bottom: none;
    flex-wrap: wrap;
}
.pipeline[data-v-1b84dabc] .tabular.menu.ui.tabular .hide {
      display: none;
}
.pipeline[data-v-1b84dabc] .tabular.menu.ui.tabular .item,
    .pipeline[data-v-1b84dabc] .tabular.menu.ui.tabular .active.item {
      border: 1px solid #f0f0f0;
      border-radius: 4px !important;
      background: white;
      margin-right: 5px;
      margin-bottom: 10px;
      padding: 0 4em 0 1.5em !important;
      min-width: 10em;
      min-height: 3.2em;
      line-height: 0.5;
      position: relative;
      white-space: nowrap;
}
.pipeline[data-v-1b84dabc] .tabular.menu.ui.tabular .item:after,
      .pipeline[data-v-1b84dabc] .tabular.menu.ui.tabular .active.item:after {
        content: none;
}
.pipeline[data-v-1b84dabc] .tabular.menu.ui.tabular .item .button,
      .pipeline[data-v-1b84dabc] .tabular.menu.ui.tabular .active.item .button {
        position: absolute;
        right: 0;
        margin-top: 0.1em;
        font-weight: 800 !important;
        background: transparent;
}
.pipeline[data-v-1b84dabc] .tabular.menu.ui.tabular .item .button:hover,
        .pipeline[data-v-1b84dabc] .tabular.menu.ui.tabular .active.item .button:hover {
          background: transparent;
}
.pipeline[data-v-1b84dabc] .tabular.menu.ui.tabular .active.item {
      background-color: #e0e1e2;
}
.pipeline[data-v-1b84dabc] .tabular.menu.ui.tabular .selection.item {
      background-color: white;
      min-width: 15em;
      padding-left: 1em;
}
.pipeline[data-v-1b84dabc] .tabular.menu.ui.tabular .selection.item .default.text {
        line-height: 2;
        padding: 0 0.5em !important;
        font-weight: 400;
}
.pipeline[data-v-1b84dabc] .tabular.menu.ui.tabular .selection.item input.search {
        margin-left: 0;
}
.pipeline[data-v-1b84dabc] .tabular.menu.ui.tabular .selection.item i.minus.icon {
        color: rgba(0, 0, 0, 0.6);
        position: absolute;
        right: 0.5em;
        top: 1.1em;
}
.pipeline[data-v-1b84dabc] .tabular.menu.ui.tabular .selection.item .menu {
        margin-top: 0 !important;
}
.pipeline[data-v-1b84dabc] .tabular.menu.ui.tabular .selection.item .menu div.item {
          background-color: white !important;
          border-radius: 0 !important;
          border-top: none;
          border-right: none;
          border-left: none;
          line-height: 2.5em;
          margin: 0;
}
.pipeline[data-v-1b84dabc] .tabular.menu.ui.tabular .selection.item .menu div.item:hover {
            background-color: #f0f0f0 !important;
}
.pipeline[data-v-1b84dabc] .tabular.menu .add {
    margin-left: 2em;
    padding: 5px 0;
}
.pipeline[data-v-1b84dabc] .tabular.menu .add button {
      background: white;
      margin-right: 0;
      border: 2px solid #f0f0f0;
}
.pipeline[data-v-1b84dabc] .table-top .pipeline.ui.toggle.checkbox {
  vertical-align: 0;
  margin-left: 2em;
}
.pipeline[data-v-1b84dabc] .ReactTable {
  border: none;
  box-shadow: none !important;
  border: none !important;
}
.pipeline[data-v-1b84dabc] .ReactTable .rt-th {
    text-align: left;
}
.pipeline[data-v-1b84dabc] .ReactTable .rt-th:first-child {
      padding-left: 1em;
}
.pipeline[data-v-1b84dabc] .ReactTable .rt-table .rt-thead .rt-tr {
    margin-top: 0 !important;
}
.pipeline[data-v-1b84dabc] .ReactTable .rt-table .rt-tbody .rt-td:first-child {
    padding-left: 1em;
}
.pipeline[data-v-1b84dabc] .ReactTable .rt-table .rt-tbody .rt-td.actions {
    text-align: center;
}
.pipeline[data-v-1b84dabc] .ReactTable .rt-table .rt-tbody .rt-td.actions .button {
      padding: 4px;
      margin: 0 1em;
}
.pipeline[data-v-1b84dabc] .ReactTable .rt-table .rt-tbody .rt-td.actions .icon {
      color: black;
      box-shadow: none;
}
.pipeline[data-v-1b84dabc] .ReactTable .rt-table .rt-tbody .rt-td.actions .icon:hover {
      color: black;
      background-color: transparent !important;
}
