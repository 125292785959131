.user-status[data-v-9babf538]  {
  height: 35px;
  background-color: #ffffff2b;
  border-radius: 30px;
  display: flex;
  align-items: center;
}
.user-status[data-v-9babf538] .selection > .text {
    color: #fff !important;
    font-weight: bold !important;
}
.user-status[data-v-9babf538] .field,
  .user-status[data-v-9babf538] .dropdown {
    font-weight: bold;
    color: #fff !important;
    background: transparent !important;
}
.user-status[data-v-9babf538] .hidden-option {
    display: none !important;
}
