.targetSelector[data-v-501e0291] .fields {
  margin: 0 !important;
  margin-bottom: 1.3em !important;
}
.targetSelector[data-v-501e0291] .field:first-of-type {
  margin: 0 5px 0 0 !important;
  padding: 0 !important;
}
.targetSelector[data-v-501e0291] .targetType {
  width: 150px;
}
.targetSelector[data-v-501e0291] .targetName {
  width: 325px;
}
