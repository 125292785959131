.ui.secondary.pointing.navbar.menu {
  background-color: initial;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  border-bottom-width: 1px; }

.ui.secondary.pointing.navbar.menu .item {
  color: #333 !important;
  padding: 0.75em 1.5em;
  margin: 0 0.5em;
  font-size: 11pt;
  min-width: 120px;
  text-align: center;
  display: inline-block; }

.ui.secondary.pointing.navbar.icon-only.menu .item {
  margin: 0;
  min-width: 80px; }

.ui.secondary.pointing.navbar.icon-only.menu .item:not(.active) > .icon {
  margin: 0;
  color: rgba(0, 0, 0, 0.6); }

.ui.secondary.pointing.navbar.menu .item:first-child {
  margin-left: 0; }

.ui.secondary.pointing.navbar.menu .item:last-child {
  margin-right: 0; }

.ui.secondary.pointing.navbar.menu .active.item {
  border-color: #000044;
  border-bottom-width: 2.5px;
  padding-bottom: 10px; }

.ui.secondary.pointing.menu .item:not(.logo):hover {
  background-color: initial !important;
  color: initial !important;
  cursor: pointer;
  border-color: #000066;
  border-bottom-width: 2px; }

.ui.secondary.pointing.menu .item:not(.active):hover {
  padding-bottom: 11px; }
