.inbox.route[data-v-9096da8c] .item.right-aligned {
  margin-left: auto;
  margin-right: 0 !important;
  -webkit-transform: scaleX(-1);
          transform: scaleX(-1);
}
.inbox.route[data-v-9096da8c] .item.right-aligned > span {
    -webkit-transform: scaleX(-1);
            transform: scaleX(-1);
}
.inbox.route[data-v-9096da8c] .new-notif-indicator {
  position: relative;
  top: 0.5em;
  right: 1em;
  z-index: 1;
}
