.modified-dropdown[data-v-1a1fba98] {
  position: absolute !important;
  z-index: 30;
}
.custom-dropdown[data-v-1a1fba98] {
  display: inline;
}
.custom-dropdown .ui.button[data-v-1a1fba98] {
    background: white;
    border: 2px solid #f0f0f0;
    padding: 8px !important;
    color: #878787;
    margin: 0 0 0 0.5em;
}
