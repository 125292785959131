.ui.form .field.permission-disabled.permission-disabled * {
  pointer-events: none;
  border: none; }

.ui.form .field.permission-disabled.permission-disabled .dropdown.icon {
  display: none; }

.ui.form .fluid-field {
  width: 100%; }

.ui.form .bottom-align {
  align-items: flex-end !important; }

.ui.form .field {
  min-width: 65px; }

.ui.form .ui.header.section-header {
  margin-top: 0 !important;
  padding-left: 0 !important; }
  .ui.form .ui.header.section-header.sub-header {
    font-size: 1em;
    color: #666; }

.phone-input .ext-input {
  width: 60px; }
  .phone-input .ext-input input {
    min-width: 60px;
    width: 60px; }

.phone-input div {
  margin-right: 0 !important; }

.and-filter .fields:not(:first-child) {
  position: relative;
  margin-top: 3em !important; }
  .and-filter .fields:not(:first-child)::before {
    content: "AND";
    top: -2.5em;
    background-color: #eee;
    color: #777;
    padding: 4px;
    border-radius: 6px;
    font-size: 0.8em;
    position: absolute;
    font-weight: bold; }

.and-filter .fields .field:first-child {
  margin-right: 5em !important;
  font-size: 0.9em; }

.or-filter.field > .multiple.selection.dropdown > .ui.label:not(:first-child),
.or-filter.multiple.selection.dropdown > .ui.label:not(:first-child) {
  position: relative;
  margin-left: 2.75em;
  cursor: default; }
  .or-filter.field > .multiple.selection.dropdown > .ui.label:not(:first-child)::before,
  .or-filter.multiple.selection.dropdown > .ui.label:not(:first-child)::before {
    top: 0.25em;
    left: -3em;
    content: "OR";
    padding: 4px;
    background-color: #eee;
    color: #777;
    padding: 4px;
    border-radius: 6px;
    font-size: 0.8em;
    position: absolute;
    font-weight: bold; }
