.file-input[data-v-8a159481] {
  display: inline-flex;
  justify-content: space-between;
  border: 1px solid rgba(34, 36, 38, 0.15);
  padding: 0.67857143em 1em;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  width: 300px;
}
.file-input ~ input[type="file"][data-v-8a159481] {
    display: none !important;
}
.file-input span[data-v-8a159481] {
    margin-left: 0.5em;
    cursor: default;
}
