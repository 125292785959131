.questions-form[data-v-9f84b15a] .buttons {
  margin-bottom: 0.5em;
}
.questions-form[data-v-9f84b15a] .buttons .ui.button {
    margin-right: 0.5em;
}
.questions-form[data-v-9f84b15a] .pages .pagination.menu {
  margin-left: 0.5em;
  -webkit-transform: scale(0.8);
          transform: scale(0.8);
  -webkit-transform-origin: left;
          transform-origin: left;
}
