.dialer-footer[data-v-d15f729a]  {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: fixed;
  bottom: 0;
  font-family: "Open Sans", Arial, Helvetica, sans-serif;
  color: white;
  margin: 0;
  border: 0;
  box-shadow: 0;
  font-size: 1rem;
  padding: 5px 1.5em 7px;
  background-color: #000066;
  z-index: 10;
}
.dialer-footer[data-v-d15f729a] .dialer-footer-btns {
    display: flex;
    justify-content: center;
}
.dialer-footer[data-v-d15f729a] .dialer-footer-btns .ui.tiny.button {
      margin-top: 3px;
}
.dialer-footer[data-v-d15f729a] .dialer-footer-left {
    display: flex;
    align-self: flex-start;
    min-width: 11em;
}
.dialer-footer[data-v-d15f729a] .dialer-footer-center {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    flex-wrap: wrap;
}
.dialer-footer[data-v-d15f729a] .dialer-footer-right {
    display: flex;
    align-self: flex-start;
    min-width: 5em;
}
.dialer-footer[data-v-d15f729a] .numpad {
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
    position: relative;
    vertical-align: middle;
    margin-right: 1em;
}
.dialer-footer[data-v-d15f729a] .numpad .icon {
      color: #000066;
      top: 0;
      left: 0;
}
.dialer-footer[data-v-d15f729a] .numpad .th.icon {
      top: 45%;
      left: 50%;
      -webkit-transform: translateX(-50%) translateY(-45%);
              transform: translateX(-50%) translateY(-45%);
      font-size: 0.9em;
}
.dialer-footer[data-v-d15f729a] .numpad .square.icon:not(.bg) {
      left: 50%;
      top: 80%;
      -webkit-transform: translateX(-50%) translateY(-20%) scaleY(0.8);
              transform: translateX(-50%) translateY(-20%) scaleY(0.8);
      border-radius: 5px;
      font-size: 0.3em;
}
.dialer-footer[data-v-d15f729a] .numpad .bg.square.icon {
      color: #fff;
      top: 0;
      border-radius: 5px;
      width: 22px;
      height: 22px;
}
.ui.page.modals.dimmer.transition.visible.active[data-v-d15f729a] {
  background-color: rgba(0, 0, 0, 0.2) !important;
}
.popup .ui.grid.relaxed.keys.centered[data-v-d15f729a] {
  padding: 5px;
}
.popup .disposition.button[data-v-d15f729a] {
  width: 100%;
  border: 1px solid rgba(34, 36, 38, 0.15);
  margin: 3px;
}
.popup .disposition.input[data-v-d15f729a] {
  width: 100%;
  margin: 3px;
}
.popup .disposition.input input[data-v-d15f729a] {
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-bottom: 1px solid rgba(34, 36, 38, 0.15);
    border-radius: 0;
}
.popup.disposition-modal .grid[data-v-d15f729a] {
  max-height: 300px;
  overflow-y: auto;
}
