.header.menu[data-v-6272b545] a.inbox.item {
  padding: 0.5em;
  margin: 0;
  position: relative;
}
.header.menu[data-v-6272b545] a.inbox.item .icon {
    margin: 0;
}
.help-links[data-v-6272b545]  {
  left: 10px !important;
}
.help-links[data-v-6272b545] .dropdown.icon {
    display: none;
}
.help-links[data-v-6272b545] .menu.transition.visible {
    top: 30px !important;
    left: -125px !important;
}
.help-links[data-v-6272b545] a {
    color: black !important;
}
.ui.menu .ui.dropdown .menu > .item[data-v-6272b545] {
  inline-size: -webkit-max-content;
  inline-size: max-content;
  min-inline-size: -webkit-max-content;
  min-inline-size: max-content;
  float: left;
}
.ui.menu .ui.dropdown .menu > .item[data-v-6272b545]:hover {
  width: 100%;
}
.ui.menu .ui.dropdown .menu > .active.item[data-v-6272b545] {
  width: 100% !important;
}
.right-menu[data-v-6272b545] {
  align-items: center;
}
