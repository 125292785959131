.route.divided {
  display: flex !important; }

.route > .main {
  padding-right: 2em;
  overflow: hidden;
  flex-grow: 1;
  padding-top: 2em !important; }

.sidebar {
  flex-shrink: 0;
  overflow: hidden;
  margin: 0;
  margin-right: 20px;
  overflow-wrap: anywhere; }

.sidebar > aside {
  margin: 0;
  margin-right: 20px;
  box-shadow: 0px 0px 20px #ccc;
  width: 300px;
  background-color: #fff;
  height: 100%; }

.sidebar .breadcrumb {
  margin-top: 1.5em !important;
  margin-left: 3em !important; }

.sidebar .title.segment {
  margin-bottom: 1em;
  padding-bottom: 0; }

.sidebar .title > .header {
  margin-left: 0.5em !important;
  margin-bottom: 0 !important; }

.sidebar .actions.segment {
  display: flex;
  margin: 0 3.5em 0 0.5em; }

.sidebar .actions .button {
  padding: 0.8em 0.6em !important;
  border-radius: 0.5rem;
  box-shadow: inset 0px 0px 2px #999; }

.sidebar .actions > div:not([hidden]) {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 2em; }

.sidebar .actions label {
  margin-top: 0.5em;
  font-size: 0.9em; }

.sidebar .info.segment {
  margin: 0;
  padding-top: 0.1em; }

.sidebar .list {
  font-size: 0.9em !important;
  color: #666; }

.sidebar .header,
.sidebar .header > a {
  color: #333; }

.sidebar .header > a:hover {
  color: #1e70bf !important; }

.sidebar .list .header {
  margin-top: 0.7em !important;
  margin-bottom: 0.3em !important; }

.sidebar .list .content > div {
  margin-bottom: 0.1em; }

.sidebar .segment {
  padding-left: 2.5em !important; }

.sidebar .gray.segment {
  padding-top: 1.3em;
  padding-bottom: 1.3em;
  background-color: #f6f6f6 !important; }

.sidebar .gray.custom.segment {
  border-top: 1px solid #d8d8d8 !important; }

.sidebar .gray.segment > .header {
  font-size: 1em;
  margin-bottom: 1.2em !important; }

.sidebar .tags .ui.mini.button,
.email-attachment.ui.button {
  background: white;
  border: 2px solid #f0f0f0;
  padding: 8px !important;
  color: #878787;
  margin: 0 0 0 0.5em; }

.dialer-ui .sidebar {
  height: auto;
  padding-bottom: 3.2em; }

.dialer-ui .sidebar .title > .header {
  margin-left: 0 !important; }

.dialer-ui .sidebar .phoneNumbers.ui.grid {
  align-items: center; }

.dialer-ui .sidebar .phoneNumbers.ui.grid > .column {
  padding: 0; }

.dialer-ui .sidebar .phoneNumbers.ui.grid > .column:first-child {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content; }

.dialer-ui .sidebar .phoneNumbers.ui.grid .ui.button {
  margin-top: 0.2em;
  margin-left: 0.2em;
  padding: 2px 6px !important;
  color: #ccc;
  font-size: 1.5em; }

.dialer.sidebar .tags .ui.mini.button {
  background: white;
  border: 2px solid #f0f0f0;
  padding: 8px !important;
  color: #878787;
  margin: 0 0 0 0.5em; }

.dialer-email {
  display: flex;
  height: 20px;
  margin-bottom: 5px; }
  .dialer-email p {
    margin-right: 10px; }
  .dialer-email .ui.mini.button {
    font-size: 0.6rem; }
