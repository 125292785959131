.actions-form[data-v-afab91b2] .selection.dropdown .visible.menu .item {
  display: flex;
  flex-direction: row-reverse;
}
.actions-form[data-v-afab91b2] .selection.dropdown .visible.menu .item .text {
    flex: 0 1;
    white-space: nowrap;
}
.actions-form[data-v-afab91b2] .selection.dropdown .visible.menu .item .description {
    flex: 1 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
